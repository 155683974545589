import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import SchemaBreadcrumb from "./schemas/SchemaBreadcrumb";

interface Props {
	pages: Page[];
	className: string;
}

export interface Page {
	name: string;
	to: string;
}
const Breadcrumb: React.FC<Props> = ({ pages, className }) => (
	<>
		<SchemaBreadcrumb pages={pages} />
		<nav className={`bg-pattern flex p-4 ${className ? className : ""}`} aria-label="Breadcrumb">
			<ol className="inline-flex justify-center px-4 py-2 text-xs text-white bg-black sm:px-6 md:mx-auto lg:px-8 xxs:text-base">
				<li className="hidden mr-2 sm:flex xxs:mr-4">
					<div className="flex items-center">
						<Link to="/" className="mr-4">
							Startseite
						</Link>
						/
					</div>
				</li>

				{pages.map((page, i) => (
					<li className="flex" key={page.name}>
						<div className={`items-center" flex ${pages.length === i + 1 ? "" : "mr-2 xxs:mr-4"}`}>
							{pages.length === i + 1 ? (
								page.name
							) : (
								<Link
									to={page.to}
									className={`${pages.length !== i + 1 && "mr-2 xxs:mr-4"} ${pages.length === i + 1 && "font-bold"}`}
								>
									{page.name}
								</Link>
							)}
							{pages.length !== i + 1 && "/"}
						</div>
					</li>
				))}
			</ol>
		</nav>
	</>
);

export default Breadcrumb;

Breadcrumb.propTypes = {
	pages: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			to: PropTypes.string.isRequired,
		})
	).isRequired,
	className: PropTypes.string,
};
Breadcrumb.defaultProps = {
	className: null,
};
