import PropTypes from "prop-types";
import React from "react";
import Container from "../common/Container";
import Divider from "../common/Divider";
import H1 from "~components/typography/H1";

function PageHeader({ title, children }) {
	return (
		<div className="bg-pattern py-6">
			<Container noPadding className="md:text-center">
				<div className="mx-auto max-w-5xl">
					<H1 display className="mb-5 font-display">
						{title}
					</H1>
					<Divider dotted className="max-w-sm md:mx-auto" />
					{children}
				</div>
			</Container>
		</div>
	);
}

export default PageHeader;

PageHeader.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};
