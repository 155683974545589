import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "../common/Button";
import H3 from "~components/typography/H3";
import P from "~components/typography/P";
import CheckboxCard from "~components/forms/elements/CheckboxCard";
import Datenschutz from "~components/forms/elements/Datenschutz";
import Input from "~components/forms/elements/Input";
import Textarea from "~components/forms/elements/Textarea";
import Wizard from "~components/forms/elements/Wizard";

const svg = {
	morgens: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="inline"
			width="64"
			height="64"
			viewBox="0 0 24 24"
			strokeWidth="1"
			stroke="currentColor"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" />
			<path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />
			<line x1="3" y1="21" x2="21" y2="21" />
			<path d="M12 9v-6l3 3m-6 0l3 -3" />
		</svg>
	),
	mittags: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="inline"
			width="64"
			height="64"
			viewBox="0 0 24 24"
			strokeWidth="1"
			stroke="currentColor"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" />
			<circle cx="12" cy="12" r="4" />
			<path d="M3 12h1M12 3v1M20 12h1M12 20v1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7M17.7 17.7l.7 .7M6.3 17.7l-.7 .7" />
		</svg>
	),
	abends: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="inline"
			width="64"
			height="64"
			viewBox="0 0 24 24"
			strokeWidth="1"
			stroke="currentColor"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" />
			<path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />
			<line x1="3" y1="21" x2="21" y2="21" />
			<path d="M12 3v6l3 -3m-6 0l3 3" />
		</svg>
	),
};

function FormGeneral({ formName, extraFields }) {
	const [step, setStep] = useState(0);

	const prevStep = (e) => {
		e.preventDefault();
		setStep((prevCount) => prevCount - 1);
	};

	const nexStep = (e) => {
		e.preventDefault();
		setStep((prevCount) => prevCount + 1);
	};

	const validateMaindata = (e) => {
		e.preventDefault();
		const inputs = document.getElementById("mainData").querySelectorAll("input");
		const errors = [];
		inputs.forEach((input) => {
			if (input.checkValidity() === false) {
				input.reportValidity();
				errors.push("Error");
			}
		});
		if (!errors.length > 0) {
			setStep((prevCount) => prevCount + 1);
		}
	};

	return (
		<form name={formName} method="POST" data-netlify="true" action="/anfrage-gesendet/" className="space-y-4">
			<input type="hidden" name="form-name" value={formName} />
			<Wizard currentStep={step}>
				<div className="space-y-4" id="mainData">
					<div className="mt-0 ">
						<H3 className="mb-2">Kontaktdaten</H3>
						<P>Diese Informationen sind wichtig, damit ich Dich kontaktieren kann.</P>
					</div>
					<Input
						type="text"
						label="Firma"
						id="firma"
						name="Firma"
						placeholder="Dein Unternehmensname"
						autoComplete="organization"
					/>

					<div className="grid-cols-2 gap-4 sm:grid ">
						<Input type="text" label="Vorname" id="vorname" name="Vorname" placeholder="Dein Vorname" required />
						<Input type="text" label="Nachname" id="nachname" name="Nachname" placeholder="Dein Nachname" required />
					</div>
					<Input
						type="tel"
						label="Telefon"
						id="telefon"
						name="Telefon"
						placeholder="Deine Telefonnummer"
						autoComplete="tel"
						inputMode="numeric"
						required
					/>
					<Input
						type="email"
						label="E-Mail"
						id="email"
						name="E-Mail"
						placeholder="Deine E-Mailadresse"
						autoComplete="email"
						required
					/>
					<div className="grid grid-cols-2 gap-3">
						<Button onClick={(e) => validateMaindata(e)} text="Weiter" className="col-start-2 w-full" />
					</div>
				</div>
				<div>
					<div className="my-4">
						<H3 className="mb-2">Wann bist Du erreichbar?</H3>
						<P>Diese Informationen sind wichtig, damit ich Dich nicht zu unpassenden Zeiten kontaktiere.</P>
					</div>
					<div className="mb-4 grid grid-cols-1 gap-4 sm:grid-cols-3">
						<CheckboxCard
							label="Morgens"
							name="Erreichbar-Morgens"
							value="Ja"
							id="erreichbarkeit-morgens"
							svg={svg.morgens}
						/>

						<CheckboxCard
							label="Mittags"
							name="Erreichbar-Mittags"
							value="Ja"
							id="erreichbarkeit-mittags"
							svg={svg.mittags}
						/>

						<CheckboxCard
							label="Abends"
							name="Erreichbar-Abends"
							value="Ja"
							id="erreichbarkeit-abends"
							svg={svg.abends}
						/>
					</div>
					<div className="grid grid-cols-2 gap-3">
						<Button onClick={(e) => prevStep(e)} text="Zurück" className="w-full" white />
						<Button onClick={(e) => nexStep(e)} text="Weiter" className="col-start-2 w-full" />
					</div>
				</div>
				<div className="space-y-4">
					<div className="my-4">
						<H3 className="mb-2">Zusatzinformationen</H3>
						<P>Gerne gebe hier weitere Informationen an, falls Du möchtest.</P>
					</div>
					{extraFields.website && (
						<Input
							type="text"
							label="Aktuelle Website (falls vorhanden)"
							id="website"
							name="Website"
							placeholder="https://beispiel.de"
							autoComplete="url"
						/>
					)}

					<Textarea
						label="Kurze Projektbeschreibung"
						id="kommentar"
						name="Kommentar"
						placeholder="Deine Beschreibung"
					/>
					<div className="mb-3 grid grid-cols-2 gap-3">
						<Button onClick={(e) => prevStep(e)} text="Zurück" className="w-full" white />
						<Button submit text="Anfrage senden" className="w-full" />
					</div>
					<Datenschutz />
				</div>
			</Wizard>
		</form>
	);
}

export default FormGeneral;

FormGeneral.propTypes = {
	formName: PropTypes.string.isRequired,
	extraFields: PropTypes.shape({
		website: PropTypes.bool,
	}),
};

FormGeneral.defaultProps = {
	extraFields: {
		website: false,
	},
};
