import React from "react";

interface Props {
	className?: string;
	dotted?: boolean;
}

const Divider: React.FC<Props> = ({ className, dotted }) => {
	if (dotted) {
		return <div className={`w-full border-b border-dotted border-black ${className ? className : ""}`} />;
	}
	return (
		<div
			className={`h-4 w-16 ${className ? className : ""}`}
			style={{
				background:
					"url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAOUlEQVQYVy3JwQ2AIBQE0dkiwAih/8JESH4TS1Dm+EZCGAPinWFtQOYZ4XZf+qCPcC3pf33uk8VpAbZuEr8+g0b7AAAAAElFTkSuQmCC) repeat",
			}}
		/>
	);
};

export default Divider;
