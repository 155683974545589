import { Link } from "gatsby";
import React from "react";

const Datenschutz = () => {
	return (
		<div className="text-center text-xs">
			<p>
				Mit dem Absenden bestätigst Du, dass Du unsere{" "}
				<Link to="/datenschutz/" className="underline">
					Datenschutzerklärung
				</Link>{" "}
				akzeptierst.
			</p>
		</div>
	);
};

export default Datenschutz;
