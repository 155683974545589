import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";

interface Props {
	text?: string;
	to?: string;
	href?: string;
	onClick?: React.MouseEvent<HTMLButtonElement, MouseEvent>;
	target?: string;
	rel?: string;
	submit?: boolean;
	white?: boolean;
	brand?: boolean;
	small?: boolean;
	children?: React.ReactNode;
	className?: string;
	anchor?: string;
}

const Button: React.FC<Props> = ({
	text,
	to,
	href,
	onClick,
	target,
	rel,
	submit,
	white,
	brand,
	small,
	children,
	className,
	anchor,
}) => {
	let classes = `inline-flex items-center justify-center border border-transparent focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-black transition-colors duration-300 ${
		small ? "px-5 py-2" : "px-5 py-3"
	} ${className || ""}`;

	if (brand) {
		classes += " bg-brand border-brand text-white hover:bg-black";
	} else if (white) {
		classes += " bg-white border-gray-300 text-black hover:bg-gray-300";
	} else {
		classes += " bg-black text-white hover:bg-gray-800";
	}

	if (to) {
		return (
			<Link to={to} className={classes}>
				{text}
				{children}
			</Link>
		);
	}

	if (anchor) {
		return (
			<AnchorLink to={anchor} title={text} className={classes}>
				{text}
				{children}
			</AnchorLink>
		);
	}

	if (href) {
		return (
			<a href={href} className={classes} target={target} rel={rel}>
				{text}
				{children}
			</a>
		);
	}

	if (onClick) {
		return (
			<button onClick={onClick} type="button" className={classes}>
				{text}
				{children}
			</button>
		);
	}

	if (submit) {
		return (
			<button type="submit" className={classes}>
				{text}
				{children}
			</button>
		);
	}

	return (
		<button onClick={onClick} type="button" className={`bg-red-600 ${classes}`}>
			Missing props
		</button>
	);
};

export default Button;
